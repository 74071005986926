import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button, Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../hooks/useAxios.ts";
import { useAuth } from "../../hooks/AuthProvider.tsx";
import useRequiredParams from "../../hooks/useRequiredParams.ts";
import { AxiosError } from "axios";

const schema = z
  .object({
    newPassword1: z.string().min(8, { message: "Password must be at least 8 characters long." }),
    newPassword2: z.string().min(8, { message: "Password must be at least 8 characters long." }),
  })
  .refine((data) => data.newPassword1 === data.newPassword2, {
    message: "Passwords must match.",
    path: ["newPassword2"], // Show the error at newPassword2 field
  });

export default function ResetPasswordPage() {
  const { token } = useRequiredParams();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const axios = useAxios();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      newPassword1: "",
      newPassword2: "",
    },
  });

  const getErrorString = (error: AxiosError): string => {
    if (error && error.response) {
      const errorData = error.response.data as { password?: string[]; error?: string; token?: string };

      if (errorData.password) {
        return errorData.password[0];
      } else if (errorData.error) {
        return errorData.error;
      } else if (errorData.token) {
        return "Email reset link invalid. If you have requested multiple password reset emails be sure to use the most recent link.";
      }
    }
    return "An error occurred while resetting the password. Make sure you haven't requested multiple ";
  };

  const onSubmit = async (data: z.infer<typeof schema>) => {
    await axios
      .post("/users/password-reset/", {
        token,
        password: data.newPassword1,
      })
      .then(() => {
        logout();
        navigate("/sign-in"); // Redirect to the sign in page after successful password reset
        alert("Password changed successfully. You can now log in with your new password.");
      })
      .catch((error) => {
        setError("newPassword1", {
          type: "manual",
          message: getErrorString(error),
        });
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <h2>Reset Your Password</h2>
      <FormGroup className="mb-3">
        <FormLabel>New Password</FormLabel>
        <FormControl type="password" isInvalid={!!errors.newPassword1} {...register("newPassword1")} />
        {errors.newPassword1 && <Form.Control.Feedback type="invalid">{errors.newPassword1.message}</Form.Control.Feedback>}
      </FormGroup>
      <FormGroup className="mb-3">
        <FormLabel>Confirm New Password</FormLabel>
        <FormControl type="password" isInvalid={!!errors.newPassword2} {...register("newPassword2")} />
        {errors.newPassword2 && <Form.Control.Feedback type="invalid">{errors.newPassword2.message}</Form.Control.Feedback>}
      </FormGroup>
      <Button type="submit">Reset Password</Button>
    </Form>
  );
}
