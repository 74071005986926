import { useNavigate } from "react-router-dom";
import { useAxios } from "../../hooks/useAxios.ts";
import { createNewGame } from "../../api/PlayGameApi.ts";
import React, { useState } from "react";
import { z } from "zod";
import { CreateGameDto, GameOpenness } from "../../dtos/GameDtos.ts";
import { UserDto } from "../../dtos/UserDtos.ts";
import AsyncSelect from "react-select/async";
import { searchUsers } from "../../api/UserSearchApi.ts";
import { MultiValue } from "react-select";
import { useAuth } from "../../hooks/AuthProvider.tsx";
import { Button } from "react-bootstrap";
import NumberPicker from "react-widgets/NumberPicker";
import "react-widgets/styles.css";

const CreateGameSchema = z.object({
  pointsToWin: z.number().int().positive().min(2, "Points to win must be at least 2"),
  openness: z.nativeEnum(GameOpenness),
  isPractice: z.boolean(),
  invitedPlayers: z.array(z.string()).optional(),
  maxPlayers: z.number().min(2).max(50).optional(),
});

export default function CreateGamePage() {
  const navigate = useNavigate();
  const axios = useAxios();
  const { username } = useAuth();

  const [selectedUsers, setSelectedUsers] = useState<UserDto[]>([]);
  const [pointsToWin, setPointsToWin] = useState("501");
  const [openness, setOpenness] = useState("open");
  const [isPractice, setIsPractice] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isUserSearchLoading, setIsUserSearchLoading] = useState(false);
  const [maxPlayers, setMaxPlayers] = useState(10);

  const userSearchRequester = async (inputValue: string) => {
    setIsUserSearchLoading(true);
    try {
      const response = await searchUsers(axios, inputValue);
      return response.data.map((user: UserDto) => ({
        label: user.username,
        value: user,
      }));
    } finally {
      setIsUserSearchLoading(false);
    }
  };

  const handleChange = (options: MultiValue<{ label: string; value: UserDto }>) => {
    setSelectedUsers(options.map((option: { label: string; value: UserDto }) => option.value));
  };

  const createGameRequest = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent default form submission behavior

    const gameData: CreateGameDto = {
      pointsToWin: parseInt(pointsToWin, 10), // Convert string to integer
      openness,
      isPractice: isPractice,
    };

    if (openness === GameOpenness.INVITE) {
      gameData.invitedPlayers = selectedUsers.map((user) => user.username);
    }

    if (openness === GameOpenness.OPEN) {
      gameData.maxPlayers = maxPlayers;
    }

    try {
      CreateGameSchema.parse(gameData); // Validate using zod schema
      createNewGame(axios, gameData).then((response) => navigate("/games/" + response.data.id));
    } catch (error) {
      if (error instanceof z.ZodError) {
        setErrorMessage(error.errors.map((err) => err.message).join(", "));
      }
    }
  };

  return (
    <>
      <div className="container mt-5">
        <h2>Create a New Game</h2>
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        <div className="form-group">
          <label htmlFor="gamePoints">Game Points To Be Played To:</label>
          <select className="form-control" id="gamePoints" name="gamePoints" value={pointsToWin} onChange={(e) => setPointsToWin(e.target.value)} required>
            <option value="301">301</option>
            <option value="501">501</option>
            <option value="701">701</option>
            <option value="901">901</option>
            <option value="1001">1001</option>
            <option value="10001">10001</option>
          </select>

          <div className="form-check mt-3">
            <input type="checkbox" className="form-check-input" id="is_practice" name="is_practice" checked={isPractice} onChange={(e) => setIsPractice(e.target.checked)} />
            <label className="form-check-label" htmlFor="is_practice">
              Is Practice Game
            </label>
          </div>

          <label htmlFor="openness" className="mt-3">
            Openness:
          </label>
          <select className="form-control" id="openness" name="openness" value={openness} onChange={(e) => setOpenness(e.target.value)} required>
            <option value={GameOpenness.OPEN}>Open</option>
            <option value={GameOpenness.FRIENDS}>Friends Only</option>
            <option value={GameOpenness.INVITE}>Invite Players</option>
          </select>
        </div>

        {openness === GameOpenness.INVITE && (
          <>
            <label>Invited Players</label>
            <AsyncSelect
              isMulti
              cacheOptions
              loadOptions={userSearchRequester}
              onChange={handleChange}
              value={selectedUsers.map((user: UserDto) => ({ label: user.username, value: user }))}
              isLoading={isUserSearchLoading}
              isOptionDisabled={(option: { label: string; value: UserDto }) => option.value.username === username}
              className="flex-grow-1"
            />
          </>
        )}

        {openness === GameOpenness.OPEN && (
          <>
            <label>Max Players:</label>
            <NumberPicker defaultValue={maxPlayers} min={2} max={50} onChange={(value) => setMaxPlayers(value!)} className="flex-grow-1" />
          </>
        )}

        <Button variant="primary" onClick={createGameRequest}>
          Create Game
        </Button>
      </div>
    </>
  );
}
