import { Link } from "react-router-dom";
import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en";
import ReactTimeAgo from "react-time-ago";
import { GameDataDto } from "../../dtos/GameDtos.ts";

TimeAgo.addDefaultLocale(en);

interface Props {
  gameData: GameDataDto;
}

export default function GameCard({ gameData }: Props) {
  return (
    <div className="col">
      <div className="card h-100">
        <div className="card-body">
          <h5 className="card-title">
            Game ID: {gameData.id} - Created By {gameData.owner}
          </h5>
          <div className="row">
            <div className="col-6 text-left">
              <p className="card-text">
                Points to Win: <span className="badge bg-info">{gameData.pointsToWin}</span>
              </p>
            </div>
            <div className="col-6 text-right">
              <p className="card-text">
                Age:{" "}
                <span className="badge bg-success text-light">
                  {" "}
                  <ReactTimeAgo date={gameData.created} locale="en-US" />
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="card-footer d-flex justify-content-between">
          <Link to={`/games/${gameData.id}`} className="btn btn-primary">
            Play Game
          </Link>
          <Link to={`/games/${gameData.id}/participants/`} className="btn btn-secondary">
            Game Details
          </Link>
        </div>
      </div>
    </div>
  );
}
