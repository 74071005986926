import { Point } from "../components/GameView/PlayGamePage/PointUtil.ts";

export enum GameOpenness {
  OPEN = "open",
  FRIENDS = "friends",
  INVITE = "invite",
}

export interface GameDataDto {
  id: number;
  pointsToWin: number;
  owner: string;
  created: Date;
  openness: GameOpenness;
}

export interface CreateGameDto {
  pointsToWin: number;
  openness: string;
  isPractice: boolean;
  maxPlayers?: number;
  invitedPlayers?: string[];
}

export interface GameViewDto {
  id: string;
  pointsToWin: number;
  owner: string;
  openness: string;
  isPractice: boolean;
  invitedPlayers: string[];
  maxPlayers: number;
  full: boolean;
  created: Date;
}

export interface PlayerTurnDto {
  id: string;
  player: string;
  turns: Point[][];
  game: string;
  isDone: boolean;
  finishingPlace: number;
}

export interface CreateGameResponseDto {
  id: number;
  pointsToWin: number;
  owner: string;
  openness: string;
  isPractice: boolean;
  maxPlayers: number;
  invitedPlayers: string[];
}
