import useSWR from "swr";
import { useFetcher } from "../hooks/userFetcher.ts";
import { GameParticipantsDto, UserDto } from "../dtos/UserDtos.ts";
import { AxiosInstance } from "axios";
import { GameOpenness } from "../dtos/GameDtos.ts";
import { UserProfileDto } from "../dtos/ProfileDtos.ts";

const gameListUrl = (gameId: string) => `/games/${gameId}/`;
const gameParticipantListUrl = (gameId: string) => `/games/${gameId}/participants/`;
const gameOpennessUrl = (gameId: string) => `/games/${gameId}/openness/`;
const gameMaxPlayersUrl = (gameId: string) => `/games/${gameId}/max-players/`;

export function useGameList(username: string) {
  const { data, error, mutate } = useSWR<UserProfileDto>(gameListUrl(username));
  return {
    profile: data,
    isLoading: !data,
    isError: !!error,
    mutate,
  };
}

export function useGameParticipantList(gameId: string) {
  const { data, error, mutate } = useSWR<GameParticipantsDto>(gameParticipantListUrl(gameId), useFetcher());
  return {
    gameParticipants: data,
    isLoading: !data,
    isError: !!error,
    mutateParticipants: mutate,
  };
}

export async function removeParticipants(axios: AxiosInstance, gameId: string, users: UserDto[]) {
  const data = {
    update_type: "remove",
    users: users.map((user: UserDto) => user.username),
  };
  await axios.post(gameParticipantListUrl(gameId), data);
}

export async function addParticipants(axios: AxiosInstance, gameId: string, users: UserDto[]) {
  const data = {
    update_type: "add",
    users: users.map((user: UserDto) => user.username),
  };
  await axios.post(gameParticipantListUrl(gameId), data);
}

export function useValidNextGameOpenesses(gameId: string) {
  const { data } = useSWR<{ validNewOpennesses: GameOpenness[] }>(gameOpennessUrl(gameId), useFetcher());
  return {
    validOpenesses: data,
  };
}

export async function editOpenness(axios: AxiosInstance, gameId: string, newOpenness: string) {
  const data = {
    openness: newOpenness,
  };
  await axios.post(gameOpennessUrl(gameId), data);
}

export async function editMaxPlayers(axios: AxiosInstance, gameId: string, newMaxPlayers: number) {
  const data = {
    maxPlayers: newMaxPlayers,
  };
  await axios.post(gameMaxPlayersUrl(gameId), data);
}
