import { useGameParticipantList } from "../../api/GameListApi.ts";
import { Link, useNavigate } from "react-router-dom";
import { UserDto } from "../../dtos/UserDtos.ts";
import { Button, ListGroup, ListGroupItem } from "react-bootstrap";
import useRequiredParams from "../../hooks/useRequiredParams.ts";
import { useGameDto } from "../../api/PlayGameApi.ts";
import { GameOpenness } from "../../dtos/GameDtos.ts";

export default function GameParticipants() {
  const navigate = useNavigate();
  const { gameId } = useRequiredParams();
  const { gameParticipants } = useGameParticipantList(gameId);
  const { gameData } = useGameDto(gameId);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h1>
          Game: {gameId} ({gameData?.openness || "..."})
        </h1>
        <Button onClick={() => navigate("edit")}>Edit</Button>
      </div>
      {gameParticipants && (
        <>
          <h2>
            Owner: <Link to={`/user/${gameParticipants!.owner.username}`}>{gameParticipants!.owner.username}</Link>
          </h2>
          {gameData && gameData.openness === GameOpenness.OPEN && (
            <h2>
              Max Players: {gameData.maxPlayers} {gameData.full && "(full)"}
            </h2>
          )}
          <h2>Participants</h2>
          {gameParticipants.finished.length > 0 && (
            <ListGroup>
              <h5>Finished - {gameParticipants.finished.length}</h5>
              {gameParticipants?.finished.map((participant: UserDto) => (
                <ListGroupItem key={participant.username} className="d-flex align-items-center justify-content-between">
                  <span>{participant.username}</span>
                  <Link to={`/user/${participant.username}`}>
                    <Button variant="primary" size="sm">
                      View Profile
                    </Button>
                  </Link>
                </ListGroupItem>
              ))}
            </ListGroup>
          )}
          {gameParticipants.unfinished.length > 0 && (
            <ListGroup>
              <h3>Unfinished - {gameParticipants.unfinished.length}</h3>
              {gameParticipants?.unfinished.map((participant: UserDto) => (
                <ListGroupItem key={participant.username} className="d-flex align-items-center justify-content-between">
                  <span>{participant.username}</span>
                  <Link to={`/user/${participant.username}`}>
                    <Button variant="primary" size="sm">
                      View Profile
                    </Button>
                  </Link>
                </ListGroupItem>
              ))}
            </ListGroup>
          )}
          {gameParticipants.notStarted.length > 0 && (
            <>
              <h3>Not Started - {gameParticipants.notStarted.length}</h3>
              <ListGroup>
                {gameParticipants?.notStarted.map((participant: UserDto) => (
                  <ListGroupItem key={participant.username} className="d-flex align-items-center justify-content-between">
                    <span>{participant.username}</span>
                    <Link to={`/user/${participant.username}`}>
                      <Button variant="primary" size="sm">
                        View Profile
                      </Button>
                    </Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </>
          )}
        </>
      )}
    </>
  );
}
